import "./App.scss";

import { Suspense, lazy, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import Tenders from "./pages/Tenders/Tenders";
import TenderDetails from "./pages/TenderDetails/TenderDetails";
import NewsDetails from "./pages/NewsDetails/NewsDetails";
import AboutUs from "./pages/AboutUs/AboutUs";
import Reports from "./pages/Reports/Reports";
import Team from "./pages/Team/Team";
import Vacancies from "./pages/Vacancies/Vacancies";
import VacancyDetails from "./pages/VacancyDetails/VacancyDetails";
import Projects from "./pages/Projects/Projects";
import ApprovedProjects from "./pages/ApprovedProjects/ApprovedProjects";
import RealizedProjects from "./pages/RealizedProjects/RealizedProjects";
import Apprisers from "./pages/Appraisers/Apprisers";
import Consultants from "./pages/Consultants/Consultants";
import Requirements from "./pages/Requirements/Requirements";
import PartnersPage from "./pages/PartnersPage/PartnersPage";
import InsuranceCompanies from "./pages/InsuranceCompanies/InsuranceCompanies";
import "./i18n";
import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import MyAccount from "./pages/MyAccount/MyAccount";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ForgotPassword from "./pages/ResetPassword/ForgotPassword/ForgotPassword";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import Chat from "./pages/Chat/Chat";
import UpdateUser from "./pages/UpdateUser/UpdateUser";
import { Helmet } from "react-helmet";
import AdminAccount from "./pages/AdminAccount/AdminAccount";
import UserApplication from "./pages/UserApplication/UserApplication";
import ChatSidebar from "./components/ChatSidebar/ChatSidebar";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet='utf-8' />
        <title>АКДФ | АЗЕРБАЙДЖАНО-КЫРГЫЗСКИЙ ФОНД РАЗВИТИЯ</title>
        <meta
          name='description'
          content='АЗЕРБАЙДЖАНО-КЫРГЫЗСКИЙ ФОНД РАЗВИТИЯ'
        />
        <meta
          name='keywords'
          content='Фонд, фонд, акдф, акфр,  кредитование, займы, финансирование, фонд развития, Азербайджан'
        />
        <link rel='canonical' href='akdf.kg' />
        <meta property='og:description' content={"Description of your page"} />
      </Helmet>
      <Suspense>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/news' element={<News />} />
          <Route path='/team' element={<Team />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/vacancies' element={<Vacancies />} />
          <Route path='/tenders' element={<Tenders />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/tenders/:id' element={<TenderDetails />} />
          <Route path='/news/:id' element={<NewsDetails />} />
          <Route path='/vacancies/:id' element={<VacancyDetails />} />
          <Route path='/approvedProjects' element={<ApprovedProjects />} />
          <Route path='/realizedProjects' element={<RealizedProjects />} />
          <Route path='/consultants' element={<Consultants />} />
          <Route path='/apprisers' element={<Apprisers />} />
          <Route path='/partnersPage' element={<PartnersPage />} />
          <Route path='/requirements' element={<Requirements />} />
          <Route path='/insuranceCompanies' element={<InsuranceCompanies />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/myAccount' element={<MyAccount />} />
          <Route path='/adminAccount' element={<AdminAccount />} />
          <Route path='/updateUser' element={<UpdateUser />} />
          <Route
            path='/user/api/v1/password/reset/confirm/:uid/:id'
            element={<ResetPassword />}
          />
          <Route
            path='/user/api/v1/account-confirm-email/:id/'
            element={<ConfirmEmail />}
          />
          <Route path='/chat/:id' element={<Chat />} />
          <Route path='/chatSidebar' element={<ChatSidebar />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
